module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-41576983-10","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kade 23 Kloosterveen / Assen","short_name":"Kade 23","start_url":"/","icon":"/codebuild/output/src3187680545/src/kade23-assen.nl/src/img/icon.png","background_color":"#ffffff","theme_color":"#FCDE5C","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"638066d03d15f1c07861c8770927633e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-nieuwbouw/gatsby-browser.js'),
      options: {"plugins":[],"referral":"kade23-assen.nl","objectcode":"N100032","afdelingscode":"907366","siteUrl":"https://www.kade23-assen.nl","trackingId":"UA-41576983-10","social":{"facebook":"","instagram":"https://www.instagram.com/derealisatie/","linkedin":"https://nl.linkedin.com/company/de-realisatie-bv"},"menuItems":[{"name":"Assen","link":"/assen","external":false},{"name":"Situatie","link":"/situatie","external":false},{"name":"Locatie","link":"/locatie","external":false},{"name":"Extra","link":"/extra","external":false},{"name":"Contact","link":"/contact","external":false}],"title":"Kade 23 Kloosterveen / Assen","titleShort":"Kade 23","background_color":"#ffffff","theme_color":"#FCDE5C"},
    }]
