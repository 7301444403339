import React from 'react'
import SocialMenu from 'gatsby-theme-nieuwbouw/src/components/Social'
import {Col, Container, Nav, Row} from 'react-bootstrap'
import { GatsbyImage } from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";

const Footer = () => {
    const data = useStaticQuery(graphql`{
        logo: file(relativePath: {eq: "logo-geel.png"}) {
            childImageSharp {
                gatsbyImageData(height: 122, quality: 100, layout: FIXED)
            }
        }
    }`)
    return (
        <footer className="footer bg-dark text-light">
            <Container
                className={'text-center'}
                fluid={'lg'}
            >
                <Row className={'d-flex justify-content-between'}>
                    <Col md={4} className={'text-md-left py-5 align-self-end'}>
                        <Nav
                            style={{
                                fontSize: '24px'
                            }}>
                            <SocialMenu />
                        </Nav>
                    </Col>
                    <Col md={4} className={'py-5 justify-self-center'}>
                        <GatsbyImage
                            className={'mx-auto'}
                            alt={'logo'}
                            image={data.logo.childImageSharp.gatsbyImageData}
                        />
                    </Col>
                    <Col md={4} className={'py-5 align-self-end'}>
                        © De Realisatie 2021 | Privacy voorwaarden
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer
