import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

interface Props {
  title: string
}

export default ({ title }: Props) => {
  const data = useStaticQuery(
      graphql`{
        file(relativePath: {eq: "logo-zwart.png"}) {
          childImageSharp {
            gatsbyImageData(width: 300, quality: 100)
          }
        }
      }
      `
  )
  return <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt={title} />;
}
