import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import Navbar from 'react-bootstrap/Navbar'
import useSiteMetadata from "gatsby-theme-nieuwbouw/src/hooks/SiteMetadata"
import Logo from "gatsby-theme-nieuwbouw/src/components/navbar/Logo"
import {Nav} from "react-bootstrap";
import LogoZwart from "./LogoZwart";

interface MenuItem {
    link: string;
    external: boolean;
    name: string;
}

interface Props {
    isHome: boolean
}

export default ({ isHome = false }: Props) => {
    const { title } = useSiteMetadata()

    const menuLeft: MenuItem[] = [
        {
            name: 'Assen',
            link: '/assen',
            external: false,
        },
        {
            name: 'Situatie',
            link: '/situatie',
            external: false,
        },
        {
            name: 'Locatie',
            link: '/locatie',
            external: false,
        },
        {
            name: 'Woningen',
            link: '/woningen',
            external: false,
        },
        {
            name: 'Beschikbaar',
            link: '/beschikbaar',
            external: false,
        },
    ];

    const menuRight: MenuItem[] = [
        {
            name: 'Extra',
            link: '/extra',
            external: false,
        },
        {
            name: 'Contact',
            link: '/contact',
            external: false,
        },
    ]

    const RenderMenuItem = ({item, eventKey}: {item: MenuItem, eventKey: string}) => {
        return (
            <Nav.Item>
                {item.external
                    ?
                    <Nav.Link href={item.link} rel="noopener noreferrer" target="_blank">{item.name}</Nav.Link>
                    :
                    <Nav.Link as={Link} to={item.link} eventKey={eventKey} className="nav-link" activeClassName="active">{item.name}</Nav.Link>
                }
            </Nav.Item>
        )
    }

    const data = useStaticQuery(graphql`
        query Brochure {
            media(titel: {eq: "Brochure Kade 23"}) {
                localFile {
                    publicURL
                }
            }
        }
    `)

    return (
        <Navbar collapseOnSelect variant={isHome ? 'light' : 'dark'} fixed={'top'} expand='lg'>
            <Navbar.Brand className={'d-lg-none'} style={{maxWidth: '50%'}}>
                <Link to="/">
                    {isHome ?
                        <LogoZwart title={title} />
                        :
                        <Logo title={title} />
                    }
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className={'d-lg-flex justify-content-between'} id="basic-navbar-nav">
                <Nav className={'ml-auto'}>
                    {menuLeft.map((item, index) => <RenderMenuItem key={index} eventKey={`left-${index}`} item={item} />)}
                </Nav>
                <Navbar.Brand className={'d-none d-lg-block'} style={{maxWidth: '50%'}}>
                    <Link to="/">
                        {isHome ?
                            <LogoZwart title={title} />
                            :
                            <Logo title={title} />
                        }
                    </Link>
                </Navbar.Brand>
                <Nav className={'mr-auto'}>
                    {menuRight.map((item, index) => <RenderMenuItem key={index} eventKey={`right-${index}`} item={item} />)}
                    <Nav.Item>
                        <Nav.Link disabled>
                            Downloads
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                          target={'_blank'}
                          disabled={!data.media?.localFile.publicURL}
                          href={data.media?.localFile.publicURL}
                        >
                            Brochure
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}